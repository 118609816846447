import './App.css';

function App() {
  return (
    <>
    <div className="App">
      <header className="App-header">
       <div className="coming-soon" >
      
         <img src = "39611-coming-soon.gif" alt=""/>
   {/* <h1>COMING SOON</h1> */}

   
       </div>
      </header>
    </div>


    </>
  );
}

export default App;
